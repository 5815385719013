import { useState, useEffect } from 'react'
import { debounce } from 'lodash'

// 在服务器端渲染时，window 对象不存在，因此需要检查其可用性
const isClient = (() => {
  try {
    return !!window
  } catch (error) {
    return false
  }
})()

/**
 * 用于判断屏幕宽度是否小于某一个值
 * @param breakpoint
 * @returns
 */
const useIsWindowWidthSmaller = (breakpoint = 768) => {
  const [isSmaller, setIsSmaller] = useState(false)

  useEffect(() => {
    if (!isClient) {
      return
    }

    // 使用 setTimeout 保证在页面渲染完毕后再获取 window.innerWidth,修复因为PC min-width 引发的问题
    const timer = setTimeout(() => {
      setIsSmaller(window.innerWidth <= breakpoint)
    }, 0)

    const handleResize = debounce(() => {
      setIsSmaller(window.innerWidth <= breakpoint)
    }, 50)

    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
      clearTimeout(timer)
    }
  }, [breakpoint, isClient])

  return isSmaller
}

export default useIsWindowWidthSmaller
