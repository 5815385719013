// 金融峰会 header
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface HeaderProps {
  /**
   * 金融峰会 header 类名
   */
  className?: string
  /**
   * 数据 dataSource;
   * btn_text 按钮文字;
   * btn_href 按钮跳转链接;
   * outline 移动端外边框;
   * mb_btn_text 移动端文案;
   */
  dataSource?: {
    btn_text: string
    btn_href: string
    outline: boolean
    mb_btn_text: string | null
  }[]
}

const Header: React.FC<HeaderProps> = ({ className, dataSource }) => {
  const headerClassName = 'fixed top-0 left-0 right-0  lg:h-[64px] h-[3rem] bg-[rgba(0,0,0,0.5)]'
  return (
    <header className={classnames(styles.headerContainer, headerClassName, className)}>
      <div className="h-full w-full px-[2rem] lg:px-0 lg:max-w-[1440px]  mx-auto  flex justify-between ">
        <h1 className="logo w-[7rem] h-full lg:ml-[110px] lg:w-[106px]">
          <a href="/" className="block w-full h-full" title="logo" />
        </h1>
        <div className="button-wrapper h-full lg:mr-[130px]">
          <ul className="flex h-full items-center">
            {dataSource?.map((item) => {
              return (
                <li className="lg:ml-[20px] ml-[14px]" key={item?.btn_text}>
                  <a
                    className={classnames(
                      'block w-full h-full leading-[18px] text-[#F0C084] lg:leading-[22px] border-btn lg:py-[4px] py-[1px] lg:px-[10px] text-[12px] lg:text-[16px]  ',
                      { ' px-[8px]': item?.outline },
                      { 'house-icon border-mb-none': item?.mb_btn_text },
                    )}
                    href={item?.btn_href}
                    title={item?.btn_text}
                    target="_blank"
                  >
                    <span className="hidden lg:inline"> {item?.btn_text}</span>
                    <span className="lg:hidden"> {item?.mb_btn_text || item?.btn_text}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
