// 金融峰会 banner 中 icon 组件
import React, { ReactNode } from 'react'
import classnames from 'classnames'

interface IconTextProps {
  /**
   * 文字左侧 icon 链接
   */
  icon_url?: string
  /**
   * 文本内容
   */
  text?: string
  /**
   * 左侧icon 的类名 默认值图片高度为25px
   */
  imgClassName?: string
  /**
   * 文本样式
   */
  style?: React.CSSProperties
  /**
   * 包裹div类名
   */
  className?: string
  /**
   * 文字span类型
   */
  textClassName?: string
  children: ReactNode
}

const IconText: React.FC<IconTextProps> = ({
  className,
  text,
  icon_url,
  style = {},
  imgClassName = '',
  children,
  textClassName,
}) => {
  return (
    <div className={classnames(className)}>
      {icon_url && (
        <img className={classnames('h-[25px]', imgClassName)} src={icon_url} alt={text || (children as string)} />
      )}
      <span className={textClassName} style={{ ...style }}>
        {text || children}
      </span>
    </div>
  )
}

export default IconText
