import React, { ReactNode } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface SectionProps {
  children?: ReactNode
  /**
   * 类名
   */
  className?: string
  /**
   * 本节标题
   */
  title?: string
  /**
   * 本节蒙层标题
   */
  maskTitle?: string
  /**
   * 标题类名
   */
  titleClassName?: string
}

const Section: React.FC<SectionProps> = ({ children, className, title, maskTitle, titleClassName }) => {
  return (
    <section className={classnames(styles.section, 'lg:pt-[100px] px-[2rem] lg:px-0 pt-[5rem]', className)}>
      <h3 className={classnames('relative z-0  text-center')}>
        <span
          className={classnames(
            'lg:leading-[84px]   lg:relative z-[1]  text-[30px] leading-[42px]  lg:text-[60px] font-semibold  title',
            titleClassName,
          )}
        >
          {title}
        </span>
        <span className="absolute z-[-1]  font-normal  text-[12px] tracking-[12px] lg:tracking-[24px] leading-[18px] top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]  lg:text-[24px] lg:leading-[34px] mask-title whitespace-nowrap">
          {maskTitle}
        </span>
      </h3>
      <div>{children}</div>
    </section>
  )
}

export default Section
