// 资料扩展区 内容组件
import React, { useState } from 'react'
import classnames from 'classnames'

import * as styles from './index.module.less'

interface ResourceContentProps {
  className?: string
  dataSource: {
    data: {
      book_name: string
      book_link: string
      book_img: any
      book_desc: string
    }[]
  }
}

const ResourceContent: React.FC<ResourceContentProps> = ({ className, dataSource }) => {
  const { data } = dataSource
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const handleClick = () => {
    const link = document.createElement('a')
    link.href = data[activeIndex]?.book_link
    link.setAttribute('target', '__blank')
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  return (
    <div className={classnames(styles.container, 'lg:flex lg:justify-center', className)}>
      <div className="lg:w-[370px] flex flex-col cursor-pointer">
        {data?.map((item, index) => {
          return (
            <div
              className={classnames('flex-1 lg:pr-[60px] lg:py-[15px] lg:pl-[15px] content-item ', {
                'content-item-active': activeIndex === index,
              })}
              onMouseOver={() => {
                setActiveIndex(index)
              }}
              onClick={handleClick}
            >
              <h3 className="lg:text-[22px]  lg:leading-[30px]">{item?.book_name}</h3>
              <p className="text-[16px] leading-[22px] content-desc">{item?.book_desc}</p>
            </div>
          )
        })}
      </div>
      <div className="lg:w-[544px] lg:h-full">
        <img
          onClick={handleClick}
          className="h-full w-full"
          src={data?.[activeIndex]?.book_img?.publicURL || data[activeIndex]?.book_img}
          alt={data?.[activeIndex]?.book_name}
        />
      </div>
    </div>
  )
}

export default ResourceContent
