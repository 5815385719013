import React, { useEffect } from 'react'
import classnames from 'classnames'
import IconText from '../IconText'
import Button from 'components/Base/Button'
import * as styles from './index.module.less'

interface BannerProps {
  className?: string
  isPc?: boolean
  dataSource: {
    imgWidth?: number
    imgHeight?: number
    img_url: { publicURL: string } | string
    img_url_mb: { publicURL: string } | string
    text_message?: { text: string; icon: { publicURL: string } | string }[]
    mb_btn_href: string
    mb_btn_text: string
  }
}

const Banner: React.FC<BannerProps> = ({ className, dataSource, isPc }) => {
  return (
    <div
      className={classnames(
        styles.banner,
        'px-[2rem] pb-[3.2rem] lg:pb-[160px] lg:px-0 mx-auto lg:max-w-[1400px]',
        className,
      )}
    >
      <div className="banner-content">
        <div className="lg:pt-[230px] pt-[10rem] ">
          <img
            className={classnames('lg:block lg:mx-auto')}
            width={dataSource?.imgWidth || 860}
            height={dataSource?.imgHeight || 192}
            src={isPc ? dataSource.img_url?.publicURL : dataSource?.img_url_mb?.publicURL}
            alt=""
          />
        </div>
        <div className="lg:mt-[100px] mt-[66px] lg:flex  justify-center">
          {dataSource?.text_message?.map((item, index) => {
            return (
              <IconText
                className={classnames('text-center mt-[8px] lg:mt-0', { 'lg:ml-[56px] ': index === 1 })}
                textClassName=" text-[#F0C084] lg:ml-[10px] text-[16px] leading-[22px]   lg:text-[24px] lg:leading-[34px]"
                imgClassName="align-sub hidden lg:inline"
                icon_url={(item?.icon?.publicURL || item?.icon) as string}
                key={item?.text}
              >
                {item?.text}
              </IconText>
            )
          })}
        </div>
        <div className="lg:hidden mt-[4rem] text-center">
          <Button
            href={dataSource?.mb_btn_href}
            className="rounded-[8px] text-[#000] font-medium px-[1.5rem] py-[1rem] border-0 banner-mb-btn"
          >
            {dataSource?.mb_btn_text}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Banner
