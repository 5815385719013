// 金融峰会落地页
import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import SEO from 'components/seo'
import Header from 'components/Fintechsummit/Header'
import Section from 'components/Fintechsummit/Section'
import Banner from 'components/Fintechsummit/Banner'
import SpeakerCard from 'components/Fintechsummit/SpeakerCard'
import AgendaModule from 'components/Fintechsummit/AgendaModule'
import ResourceContent from 'components/Fintechsummit/ResourceContent'
import ResourceSwiper from 'components/Fintechsummit/ResourceSwiper'
import Committee from 'components/Fintechsummit/Committee'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import classnames from 'classnames'
import * as styles from './index.module.less'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])

interface FintechsummitProps {}
const Fintechsummit: React.FC<FintechsummitProps> = () => {
  return (
    <Layout showFloat={false} showBackTop={false} showPhoneCall={false} header={<></>} hideFooter>
      <FintechsummitState />
    </Layout>
  )
}

interface FintechsummitStateProps {}
const FintechsummitState: React.FC<FintechsummitStateProps> = () => {
  const { fintechsummitYaml } = useStaticQuery(graphql`
    query {
      fintechsummitYaml {
        title
        desc
        keywords
        header {
          btn_text
          btn_href
          outline
          mb_btn_text
        }
        banner {
          img_url {
            publicURL
          }
          img_url_mb {
            publicURL
          }
          imgWidth
          imgHeight
          paddingTop
          text_message {
            text
            icon {
              publicURL
            }
          }
          mb_btn_text
          mb_btn_href
        }
        highlight {
          title
          mask_title
          content
        }
        speakers {
          title
          mask_title
          person_array {
            name
            position
            img_url {
              publicURL
            }
          }
        }
        agenda {
          title
          mask_title
          agenda_array {
            time
            mb_time
            icon {
              publicURL
            }
            content {
              message
              person
              date
            }
            array {
              tag
              desc
              data {
                message
                person
                date
              }
            }
            number
            isExpanded
          }
        }
        resources {
          title
          mask_title
          data_source {
            tag
            data {
              book_name
              book_desc
              book_img {
                publicURL
              }
              book_link
            }
          }
        }
        committee {
          title
          mask_title
          data_source {
            title
            icons {
              img_url {
                publicURL
              }
            }
          }
        }
        call_us {
          title
          data {
            text
            href
            icon {
              publicURL
            }
            icon_style {
              width
              height
              mb_width
              mb_height
            }
          }
        }
      }
    }
  `)
  const isMb = useIsWindowWidthSmaller()
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const { header, banner, highlight, speakers, agenda, resources, committee, call_us } = fintechsummitYaml
  return (
    <>
      <SEO
        title={fintechsummitYaml?.title}
        description={fintechsummitYaml?.desc}
        keywords={fintechsummitYaml?.keywords}
      />
      <Header className="z-[9]" dataSource={header} />
      <main className={classnames('bg-[#000] text-[#f6c78d]', styles.main)}>
        <Banner isPc={!isMb} dataSource={banner} />
        {/* 大会简介 */}
        <Section
          className={classnames('!pt-[2rem] lg:!pt-[90px]', styles.highlight)}
          title={highlight?.title}
          maskTitle={highlight?.mask_title}
        >
          <div className="lg:mt-[100px] relative font-normal mt-[9rem] pb-[5.5rem] pt-[2.6rem] px-[1.6rem] lg:h-[326px] text-[14px] lg:pt-[60px] lg:pl-[70px] lg:pr-[150px]  lg:w-[1016px] lg:text-[16px] leading-[32px]  mx-auto highlight-wrapper">
            <div className="text-justify" dangerouslySetInnerHTML={{ __html: highlight?.content }}></div>
            <div className="absolute lg:w-[184px] w-[144px] h-[144px] lg:h-[184px] highlight-icon lg:left-[-100px] top-[-64px] left-[-10px] lg:top-[-42px]" />
          </div>
        </Section>
        {/* 嘉宾阵容 */}
        {/* 删除嘉宾阵容板块 */}
        {/* <Section
          className={classnames('relative', styles.speaker)}
          title={speakers?.title}
          maskTitle={speakers?.mask_title}
        >
          <div className="lg:mt-[100px] mt-[2.4rem]  max-w-[1200px] mx-auto ">
            <Swiper navigation slidesPerView={isMb ? 3 : 5} spaceBetween={isMb ? 0 : 20}>
              {speakers?.person_array.map((item) => {
                return <SwiperSlide>{<SpeakerCard data={item} />}</SwiperSlide>
              })}
            </Swiper>
          </div>
        </Section> */}
        {/* 大会议程 */}
        <Section className={classnames('relative', styles.agenda)} title={agenda?.title} maskTitle={agenda?.mask_title}>
          <div className="max-w-[1100px] lg:px-[50px] mt-[24px]  lg:mt-[70px] mx-auto">
            {agenda?.agenda_array?.map((item) => {
              return <AgendaModule className="mt-[1rem] lg:mt-[15px]" key={item?.desc} data={item} />
            })}
          </div>
        </Section>
        {/* 资料拓展专区 */}
        <Section className={styles.resources} title={resources?.title} maskTitle={resources?.mask_title}>
          <div>
            <ul className="flex lg:pt-[50px] pt-[2.6rem] lg:max-w-[1440px] justify-between lg:justify-center  lg:mx-auto">
              {resources?.data_source?.map((item, index) => {
                return (
                  <li
                    className={classnames(
                      'lg:text-[22px] lg:leading-[44px] text-[18px] leading-[22px] lg:cursor-pointer lg:px-[26px]  resources-item ',
                      {
                        'resources-item-active': activeIndex === index,
                      },
                    )}
                    key={item?.tag}
                    onClick={() => {
                      setActiveIndex(index)
                    }}
                  >
                    <span>{item?.tag}</span>
                  </li>
                )
              })}
            </ul>
            <ResourceContent
              className="hidden lg:block lg:pt-[60px]"
              dataSource={resources?.data_source?.[activeIndex]}
            />
            <ResourceSwiper dataSource={resources?.data_source?.[activeIndex]} className="lg:hidden mt-[2.2rem] " />
          </div>
        </Section>
        {/* 论坛合作伙伴 */}
        <Section className="lg:relative " title={committee?.title} maskTitle={committee?.mask_title}>
          <div className="pt-[2.4rem] lg:pt-[50px]">
            {committee?.data_source?.map((item) => {
              return <Committee className="mt-[2rem] lg:mt-[45px] lg:relative" dataSource={item} key={item?.title} />
            })}
          </div>
        </Section>
        {/* 参会咨询 */}
        <section className={classnames('lg:mt-[100px] mt-[6rem] px-[2rem] lg:px-0 ', styles.callUs)}>
          <h2 className="call-us-title">{call_us?.title}</h2>
          <div className="flex lg:relative mt-[2.6rem] lg:mt-[20px] justify-around lg:justify-center items-center flex-wrap">
            {call_us?.data?.map((item) => {
              return (
                <div className="flex items-center mb-[10px] lg:mb-[0]   lg:ml-[50px]" key={item?.text}>
                  <img
                    className={classnames(
                      'lg:mr-[10px] mr-[4px] h-[1.6rem]',

                      // `w-[${item?.icon_style?.mb_width}px] h-[${item?.icon_style?.mb_height}px]`,
                      // `lg:!w-[${item?.icon_style?.width}px] lg:!h-[${item?.icon_style?.height}px]`,
                    )}
                    src={item?.icon?.publicURL || item?.icon}
                    alt={item?.text}
                  />
                  <a href={item?.href} className="call-us-message whitespace-nowrap">
                    {item?.text}
                  </a>
                </div>
              )
            })}
          </div>
        </section>
        <footer className={classnames(styles.footer, 'lg:relative')}>
          <div className=" text-[#F0C084] leading-[44px] lg:leading-[60px]   mt-[6rem] lg:mt-[158px] text-center lg:text-[12px] text-[14px] footer-container">
            <span className="inline lg:hidden"> 神策网络科技（北京）有限公司</span>
            <span className="text-[14px] px-[6px]  lg:inline hidden ">
              {
                <>
                  © 2022 神策数据 <a href="//beian.miit.gov.cn">京ICP备15055677号-1</a>
                </>
              }
            </span>
            <span className="text-[14px] px-[6px] lg:inline hidden ">
              {
                <a
                  href="//www.beian.gov.cn/portal/recordQuery?token=eb719653-c4af-4774-88e3-2229635ebc23"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="//sensorsdata.cn/assets/img/website_filling_d0289dc.jpg" alt="website" /> 京公网安备
                  11010802027887号
                </a>
              }
            </span>
            <span className="text-[14px] px-[6px] lg:inline hidden ">
              <a
                href="https://sensorsdata.cn/assets/figure/pdf/2022/%E7%A5%9E%E7%AD%96%E6%95%B0%E6%8D%AE%E5%B9%BF%E6%92%AD%E7%94%B5%E8%A7%86%E8%8A%82%E7%9B%AE%E5%88%B6%E4%BD%9C%E7%BB%8F%E8%90%A5%E8%AE%B8%E5%8F%AF%E8%AF%812022221.pdf"
                target="_blank"
              >
                广播电视节目制作经营许可证（京）字第22320号
              </a>
            </span>
          </div>
        </footer>
      </main>
    </>
  )
}

export default Fintechsummit
