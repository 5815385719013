// 金融峰会 大会议程 模块
import React, { CSSProperties, useState, useRef } from 'react'
import SwiperCore, { Scrollbar, Pagination, EffectFade, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import classnames from 'classnames'
import * as styles from './index.module.less'
import iconBottom from 'assets/images/fintechsummit2023/arrow_yellow_bottom.png'
import iconTop from 'assets/images/fintechsummit2023/arrow_yellow_top.png'

interface ExtendedCSSProperties extends CSSProperties {
  '--hover-image': string
  '--click-image': string
}
SwiperCore.use([Scrollbar, Pagination, EffectFade, Controller])

interface AgendaModuleProps {
  className?: string
  data: {
    /**
     * 左上角 数字 标签
     */
    icon: string
    /**
     * 时间文字
     */
    time: string
    /**
     * 内容
     */
    content?:
      | {
          message?: string
          person?: string
          date?: string
        }[]
      | null
    array?: any[] | null
    /**
     * 序号
     */
    number: number
    /**
     * 是否展开
     */
    isExpanded?: boolean
    mb_time?: string
  }
}

const AgendaModule: React.FC<AgendaModuleProps> = ({ className, data }) => {
  const { icon, time, mb_time = '', isExpanded } = data
  const [style, setStyle] = useState<ExtendedCSSProperties>({
    '--hover-image': `url(${icon?.publicURL || icon})`,
    '--click-image': `url(${isExpanded ? iconTop : iconBottom})`,
  })
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!isExpanded) // 是否展开
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const myTopSwiper = useRef<SwiperCore>()
  const myBottomSwiper = useRef<SwiperCore>()
  //   点击该模块
  const handleClickModule = (event) => {
    if (!data?.content && !data?.array) {
      return
    }

    //   设置是否展开标杆
    setIsCollapsed((isCollapsed) => {
      // 依据标杆值重置样式
      setStyle((style) => {
        return {
          ...style,
          '--click-image': `url(${!isCollapsed ? iconTop : iconBottom})`,
        }
      })
      return !isCollapsed
    })
    event?.target?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  return (
    <div style={style} className={classnames(styles.container, 'lg:pt-[50px] ', className)}>
      <div
        className={classnames(
          'lg:w-[100%]  lg:h-[76px] h-[5rem] pl-[12px]   lg:pl-[26px] lg:font-medium lg:text-[22px] text-[14px] leading-[20px] lg:leading-[76px] agenda-title',
          { 'agenda-allow-click': data?.content?.length || data?.array?.length },
        )}
        onClick={handleClickModule}
      >
        <span className="hidden lg:block">{time}</span>
        <span className="lg:hidden">{mb_time}</span>
      </div>
      <div
        className={classnames('agenda-content', {
          'agenda-content-active': isCollapsed,
          'overflow-hidden': !isCollapsed,
        })}
      >
        <div>
          {!!data?.array && (
            <>
              {/* pc */}
              <div className="w-full  hidden lg:flex h-[100px] items-center agenda-content-wrapper">
                {data?.array?.map((item, index) => {
                  return (
                    <div
                      key={index + '1'}
                      className={classnames('flex-1 text-center cursor-pointer agenda-content-item', {
                        'agenda-content-item-active': index === activeIndex,
                      })}
                      onClick={() => setActiveIndex(index)}
                    >
                      <h3 className="text-[22px] leading-[30px]">{item?.tag}</h3>
                      <p className="text-[16px] leading-[22px]">{item?.desc}</p>
                    </div>
                  )
                })}
              </div>
              <ul className="lg:block hidden lg:pt-[45px] pt-[3rem] lg:pb-[80px] pb-[3rem]">
                {data?.array[activeIndex]?.data?.map((item) => {
                  return (
                    <li
                      key={item?.message + '2'}
                      className="lg:w-[708px]  lg:text-[22px] text-[1.4rem] leading-[1.6rem] lg:leading-[30px] lg:text-[#e5b77b] lg:h-[88px] mr-0 ml-auto pb-[18px] lg:pb-0 lg:mb-[18px] flex relative justify-between lg:justify-center lg:items-center  agenda-item"
                    >
                      {/* 左侧时间 */}
                      <div className="lg:absolute lg:text-[22px]  top-1/2 lg:translate-y-[-50%] left-[-194px] agenda-time">
                        {item?.date}
                      </div>
                      <div className=" w-[216px] lg:w-[90%] whitespace-pre-wrap">
                        <p className="text-[14px] lg:text-[22px] agenda-message ">{item?.message}</p>
                        <p className="text-[12px] lg:text-[16px] agenda-person">{item?.person}</p>
                      </div>
                      {/* 左侧圆环 */}
                      <div className=" w-[16px] h-[16px] rounded-[50%] px-[4px] py-[4px] bg-[#896E4A] agenda-circle " />
                    </li>
                  )
                })}
              </ul>
              {/* mb */}
              <div className="lg:hidden pt-[1.6rem]  agenda-content-mb">
                <div>
                  <Swiper
                    className="swiper-title-mb"
                    scrollbar
                    onSwiper={(swiper) => {
                      myTopSwiper.current = swiper
                    }}
                    controller={{
                      control: myBottomSwiper.current,
                    }}
                  >
                    {data?.array?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 'mb'}>
                          <div className="text-[14px] leading-[14px]">{`${item?.tag}：${item?.desc}`}</div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                  <Swiper
                    className="swiper-content-mb"
                    pagination
                    onSwiper={(swiper) => {
                      myBottomSwiper.current = swiper
                    }}
                    controller={{
                      control: myTopSwiper.current,
                    }}
                  >
                    {data?.array?.map((item, index) => {
                      return (
                        <SwiperSlide key={index + 'content-mb'}>
                          {item?.data?.map((items, index) => {
                            return (
                              <div
                                key={index + 'content-mb-children'}
                                className="lg:w-[708px]  lg:text-[22px] text-[14px] leading-[16px] lg:leading-[30px] lg:text-[#e5b77b] lg:h-[88px] mr-0 ml-auto pb-[18px] lg:pb-0 lg:mb-[18px] flex relative justify-between lg:justify-center lg:items-center  agenda-item"
                              >
                                {/* 左侧时间 */}
                                <div className="lg:absolute lg:text-[22px] top-1/2 lg:translate-y-[-50%] left-[-194px] agenda-time">
                                  {items?.date}
                                </div>
                                <div className=" w-[216px] lg:w-[90%] whitespace-pre-wrap">
                                  <p className="text-[14px] lg:text-[22px] agenda-message ">{items?.message}</p>
                                  <p className="text-[12px] mt-[4px] lg:text-[16px] ">{items?.person}</p>
                                </div>
                                {/* 左侧圆环 */}
                                <div className=" w-[16px] h-[16px] rounded-[50%] px-[4px] py-[4px] bg-[#896E4A] agenda-circle " />
                              </div>
                            )
                          })}
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>
              </div>
            </>
          )}
          {!!data?.content && (
            <ul className="lg:pt-[60px] pt-[3rem] lg:pb-[80px] ">
              {data?.content?.map((item) => {
                return (
                  <li
                    key={item?.message}
                    className="lg:w-[708px]  lg:text-[22px] text-[14px] leading-[16px] lg:leading-[30px] lg:text-[#e5b77b] lg:h-[88px] mr-0 ml-auto pb-[18px] lg:pb-0 lg:mb-[18px] flex relative justify-between lg:justify-center lg:items-center  agenda-item"
                  >
                    {/* 左侧时间 */}
                    <div className="lg:absolute lg:text-[22px] top-1/2 lg:translate-y-[-50%] left-[-194px] agenda-time">
                      {item?.date}
                    </div>
                    <div className=" w-[216px] lg:w-[90%] whitespace-pre-wrap">
                      <p className="text-[14px] lg:text-[22px] agenda-message ">{item?.message}</p>
                      <p className="text-[12px] lg:text-[16px] lg:mt-0 mt-[4px] agenda-person">{item?.person}</p>
                    </div>
                    {/* 左侧圆环 */}
                    <div className=" w-[16px] h-[16px] rounded-[50%] px-[4px] py-[4px] bg-[#896E4A] agenda-circle " />
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default AgendaModule
