import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface CommitteeProps {
  className?: string
  dataSource: any
}

const Committee: React.FC<CommitteeProps> = ({ className, dataSource }) => {
  return (
    <div className={classnames(styles.container, 'lg:flex lg:items-center lg:justify-center ', className)}>
      <div className="committee-title text-[18px] lg:text-[22px] text-center lg:leading-[22px]">
        {dataSource?.title}
      </div>
      <div className="flex committee-content">
        {dataSource?.icons?.map((item, index) => {
          return (
            <div key={index} className="lg:ml-[26px] committee-item">
              <img
                className="lg:h-[55px] w-full lg:w-[138px]"
                src={item?.img_url?.publicURL || item?.img_url}
                alt={dataSource?.title}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Committee
