// 资料扩展专区移动端轮播图模块
import React from 'react'
import classnames from 'classnames'
import SwiperCore, { Pagination, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './index.module.less'

SwiperCore.use([Pagination, EffectFade])

interface ResourceContentProps {
  className?: string
  dataSource: {
    data: {
      book_name: string
      book_link: string
      book_img: any
      book_desc: string
    }[]
  }
}

const ResourcesSwiper: React.FC<ResourceContentProps> = ({ className, dataSource }) => {
  const { data } = dataSource
  const handleClick = (book_link) => {
    return () => {
      const link = document.createElement('a')
      link.href = book_link
      link.setAttribute('target', '__blank')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }
  return (
    <div className={classnames(styles.container, className)}>
      <div>
        <Swiper spaceBetween={10} pagination>
          {data?.map((item) => {
            return (
              <SwiperSlide key={item?.book_name}>
                <div
                  onClick={handleClick(item?.book_link)}
                  className="resources-swiper-item rounded-[8px] pb-[40px] overflow-hidden"
                >
                  <div>
                    <img
                      className="w-full h-[186px]"
                      src={item?.book_img?.publicURL || item?.book_img}
                      alt={item?.book_name}
                    />
                  </div>
                  <div className="px-[14px] py-[14px] bg-[#2d2419] item-content">
                    <h3 className="">{item?.book_name}</h3>
                    <p>{item?.book_desc}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default ResourcesSwiper
